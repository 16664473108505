import React, { Fragment, useState, useContext } from 'react';
import styled from 'styled-components';
import fetch from 'isomorphic-unfetch';
import { graphql, useStaticQuery } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';

import * as modalTypes from '../types/modalTypes';
import { Container, Inner } from './Elements';
import ModalContext from '../store/modalContext';
import { font, colors } from '../consts/style';
import * as endpoints from '../consts/endpoints';
import mq from '../style/mq';
import { RECAPTCHA_SITE_KEY } from '../consts/captcha';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Inner} {
    width: 100%;
    max-width: 90rem;

    .heading {
      text-align: center;
      h3 {
        ${font.h2};
      }
    }
    .body {
      max-width: 55rem;
      width: 100%;
      margin: auto;
      text-align: center;
      padding-top: 1.2rem;
      padding-bottom: 3.2rem;
      ${font.p};
    }

    form {
      display: flex;
      flex-wrap: wrap;
      transition: all 0.4s ease;

      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }

      label {
        ${font.p};
      }

      input,
      textarea {
        width: 100%;
        background: ${colors.dark};
        border: 0.2rem solid ${colors.darkPurple};
        border-radius: 0.4rem;
        padding: 0.4rem 0.8rem;
        color: white;
        ${font.p};
      }

      textarea {
        height: 22rem;
      }

      .input-wrap {
        width: 100%;
        margin-bottom: 1.6rem;
      }

      .input-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .input-wrap {
          width: 49%;
          margin-right: 2%;
          &:nth-child(2n) {
            margin-right: 0;
          }
          ${mq.mobile`
            width: 100%;
            margin-right: 0;
          `}
        }
      }
    }
    .captcha {
      margin: 0 auto;
    }
    .button {
      width: 100%;
      text-align: center;
      button {
        width: 100%;
        max-width: 30rem;
        margin-top: 1.2rem;
        ${font.button};
      }
    }
  }
`;

const contactQuery = graphql`
  {
    datoCmsContact {
      heading
      body
      confirmationMessage
      adminEmail
    }
  }
`;

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [industry, setIndustry] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [validated, setValidated] = useState(null);
  const [loading, setLoading] = useState(false);

  const { openModal } = useContext(ModalContext);

  const { datoCmsContact } = useStaticQuery(contactQuery);

  function getFormClass() {
    let c = '';
    if (loading) c += 'loading ';
    return c;
  }

  function onCaptcha(value) {
    setValidated(value);
  }

  function handleSubmit(e) {
    if (e) e.preventDefault();
    if (loading || !validated) return;
    setLoading(true);

    const body = JSON.stringify({
      name,
      email,
      company,
      industry,
      subject,
      description,
      adminEmail: datoCmsContact.adminEmail,
    });

    fetch(endpoints.CONTACT, {
      method: 'post',
      body,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then((res) => res.json())
      .then(() => {
        openModal(modalTypes.CONFIRMATION, {
          message: datoCmsContact.confirmationMessage,
        });
        setLoading(false);
        setName('');
        setEmail('');
        setCompany('');
        setIndustry('');
        setSubject('');
        setDescription('');
      });
  }

  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <div className="heading">
              <h3>{datoCmsContact.heading}</h3>
            </div>
            <div
              className="body"
              dangerouslySetInnerHTML={{ __html: datoCmsContact.body }}
            />
            <form className={getFormClass()} onSubmit={handleSubmit}>
              <div className="input-group">
                <div className="input-wrap">
                  <label htmlFor="contact-name">Your Name:</label>
                  <input
                    id="contact-name"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                </div>
                <div className="input-wrap">
                  <label htmlFor="contact-email">Your Email:</label>
                  <input
                    id="contact-email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-wrap">
                  <label htmlFor="contact-company">Company:</label>
                  <input
                    id="contact-company"
                    type="text"
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                    required
                  />
                </div>
                <div className="input-wrap">
                  <label htmlFor="contact-industry">Industry:</label>
                  <input
                    id="contact-industry"
                    type="text"
                    onChange={(e) => setIndustry(e.target.value)}
                    value={industry}
                    required
                  />
                </div>
              </div>
              <div className="input-wrap">
                <label htmlFor="contact-subject">Copy Subject/Title:</label>
                <input
                  id="contact-subject"
                  type="text"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                />
              </div>
              <div className="input-wrap">
                <label htmlFor="contact-description">Copy Description:</label>
                <textarea
                  id="contact-description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </div>
              <div className="captcha">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={onCaptcha}
                  theme="dark"
                />
              </div>
              <div className="button">
                <button type="submit" disabled={!validated}>
                  <span>{loading ? 'Sending...' : 'Submit'}</span>
                </button>
              </div>
            </form>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
